<template>
  <label>
    <div class="label-container">
      <p v-if="label">{{ label }}</p>
      <div class="lds-ring" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="select-container">
      <select
        @blur="$emit('blur')"
        @input="$emit('select-option', $event.target.value)"
      >
        <option value="" selected disabled>Select {{ label }} </option>

        <slot :selected-value="value"></slot>
      </select>
      <div class="arrow-icon">
        <CaretIcon />
      </div>
      <p class="input-error">{{ errorMessage ? errorMessage : "" }}</p>
    </div>
  </label>
</template>

<script>
import CaretIcon from "../icons/CaretIcon.vue";

export default {
  components: {
    CaretIcon
  },
  name: "BaseInput",
  emits: ["blur", "select-option"],

  data() {
    return {};
  },

  props: {
    label: {
      required: false
    },
    value: { type: String },
    selected: { type: String },
    loading: {
      type: Boolean
    },
    errorMessage: {
      type: String
    }
  }
};
</script>

<style scoped>
select {
  display: block;
  width: 100%;
  padding: 1rem 10px;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #8e8e8e;
  font-size: 1rem;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

select:focus {
  outline: 1px solid #2b55ed;
}

.select-container {
  position: relative;
}
.arrow-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
label {
  color: #57595a;
  font-size: 14px;
  line-height: 1.8;
  position: relative;
  width: 100%;
}

.input-error {
  color: rgb(234, 0, 0);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
}

/* LOADER */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 2px;
  border: 2px solid #2b55ed;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2b55ed transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.label-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
</style>
