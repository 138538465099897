<template>
    <div class="holder">
        <div class="container__head mt-4">
            <h3>Add property</h3>
            <p class="mb-2">
              Please provide us with the details about the property in question.
            </p>
            <p class="mb-3">Add properties owned by this landlord.</p>
          </div>
        <div class="form">
            <div class="email-container input-full">
                <BaseInput
                type="text"
                placeholder="Eg: newly built 3 bedroom flat"
                label="Title"
                :value="propertyTitle"
                @input="propertyTitle = $event"
                :errorMessage="errorMessage.title"
                @blur="validateTitleInput"
                />
            </div>

                
            <div class="input-group">
                <InputSelect
                label="Property Type"
                @select-option="propertyType = $event"
                @blur="validatePropertyType"
                :value="propertyType"
                :errorMessage="errorMessage.propertyType"
                :loading="loadingPropertyType"
                >
                <option
                    v-for="propertyType in propertyTypes"
                    :key="propertyType.id"
                    :value="propertyType.type"
                    >{{ propertyType.type }}</option
                >
                </InputSelect>
                <InputSelect
                label="Property subtype"
                @select-option="propertySubType = $event"
                @blur="validatePropertySubType"
                :value="propertySubType"
                :errorMessage="errorMessage.propertySubType"
                :loading="loadingPropertySubType"
                >
                <option
                    v-for="subType in propertySubTypes"
                    :key="subType.id"
                    :value="subType.subtype"
                    >{{ subType.subtype }}</option
                >
                </InputSelect>
                
            </div>

            <div class="input-group">
                <InputSelect
                label="Business Type"
                @select-option="typeofbusiness = $event"
                @blur="validateBusinessType"
                :value="typeofbusiness"
                :errorMessage="errorMessage.typeofbusiness"
                >
                <option selected disabled>Select</option>
                <option  v-for="item in businesstypenumber"
                    :key="item.id"
                    :value="item.id">{{ item.businesstype }}</option>
                </InputSelect>
            </div>
            <div class="input-group">
                <InputSelect
                label="State"
                @select-option="location = $event"
                @blur="validateLocation"
                :value="location"
                :errorMessage="errorMessage.location"
                :loading="loadingLocation"
                >
                <option
                    v-for="state in sortedStates"
                    :key="state.id"
                    :value="state.name"
                    >{{ state.name }}</option
                >
                </InputSelect>

                <InputSelect
                label="City"
                @select-option="city = $event"
                @blur="validateCity"
                :value="city"
                :errorMessage="errorMessage.city"
                :loading="loadingCity"
                >
                <option
                    v-for="city in sortedCity"
                    :key="city.id"
                    :value="city.name"
                    >{{ city.name }}</option
                >
                </InputSelect>
                
            </div>

            <div class="email-container input-full">
                <BaseInput
                type="text"
                placeholder="Enter property accurate address"
                label="Property address"
                :value="propertyAddress"
                @input="propertyAddress = $event"
                :errorMessage="errorMessage.propertyAddress"
                @blur="validatePropertyAddress"
                />
            </div>
            <div class="input-group">
                <BaseInput
                type="textarea"
                placeholder="Provide landmarks or directions, a detailed property description, and any additional remarks."
                label="Property description"
                :value="description"
                @input="description = $event"
                :errorMessage="errorMessage.description"
                @blur="validateDescription"
            />
            </div>
            <div class="addNewUnit">
                <div class="unit-text-button">
                <h5>Units</h5>
                <button class="btn" @click="addForm()"><span>+</span> Add unit</button>
                </div>
                <h6>Add the individual units within this property. Each unit can be tracked and updated separately.</h6>
            </div>
              
            <div v-for="(form, index) in forms" :key="index">
                <div class="unit-count-holder">
                <h3>Unit {{ index + 1 }}</h3>
                <div v-if="forms.length > 1 && index != 0">
                    <!-- <small>Save</small> -->
                    <img style="cursor: pointer;" @click="removeForm(index)" src="../assets/icons/trash.svg" alt="delete">
                </div>
                </div>
    
                <form @submit.prevent="submitForm(index)">
                <div class="input-group">
                    <div class="row">
                    <div class="col-md-4 form-group">
                        <label for="name">Unit name:</label>
                        <select class="form-control dynamic-input" id="bathroom" v-model="form.unit_name">
                            <option  value="" disabled>Select</option>
                            <option
                            v-for="(category, index) in propertyCategory"
                            :key="index"
                            :value="`${category.type}-${category.acronym}`"
                            >{{ category.type }} {{ category.acronym }}</option
                            >
                  
                      </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="price">Unit Amount:</label>
                        <input class="form-control dynamic-input" id="price" type="text" v-model="form.price" placeholder="Enter unit amount" />  
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="bedrooms">Number of bedrooms:</label>
                        <select class="form-control dynamic-input" id="bedroom" v-model="form.bedrooms">
                        <option  value="" selected disabled>Select</option>
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="bedrooms">Number of bathrooms:</label>
                        <select class="form-control dynamic-input" id="bathroom" v-model="form.bathrooms">
                        <option  value="" selected disabled>Select</option>
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="bedrooms">Number of toilets:</label>
                        <select class="form-control dynamic-input" id="toilets" v-model="form.toilets">
                        <option  value="" selected disabled>Select</option>
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="status">Status:</label>
                        <select class="form-control dynamic-input" name="status" id="status" v-model="form.status">
                        <option value="" disabled>Select status</option>
                        <option
                            v-for="status in propertyStatuses"
                            :key="status"
                            :value="status"
                            >{{ status }}</option
                        >
                        </select>
                        
                    </div>
                
                    </div>
                </div>
                <!-- <div>
                    <label for="name">Name:</label>
                    <input class="form-control dynamic-input" type="text" v-model="form.name" />
                </div>

                <div>
                    <label for="email">Email:</label>
                    <input class="form-control dynamic-input" type="email" v-model="form.email" />
                </div> -->

                <!-- <button type="submit">Submit Form {{ index + 1 }}</button> -->
                </form>
            </div>
            <div class="col-md-4 form-group pl-0">
              <label for="bedrooms">Duplicate unit (optional): <span>{{ forms.length }}</span></label>
              <input class="form-control dynamic-input" id="price" type="number" v-model="duplicateFormNumber" placeholder="Enter number of times..." />
            </div>
            <div class="green-yellow-btn">
                <button class="btn cancel" @click="backToLandlordList()">Cancel</button>
                <button class="btn save" @click="validateForm2" :disabled="submittingProp">
                 {{ submittingProp ? 'Processing...' : 'Add property' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// import CF from "../helpers/currencyFormatter";
// import Loader from './Loader';
import properties from "../services/properties";
// import BaseButtton from "../components/UI/BaseButtton.vue";
import BaseInput from "../components/form/BaseInput.vue";
import InputSelect from "../components/form/InputSelect.vue";


// import $ from "jquery";
export default {
  name: "AddPropertyLandlord",
  components: {
    // Loader,
    BaseInput,
    // BaseButtton,
    InputSelect
  },
  data() {
    return {
      loading: false,
      submittingProp: false,
      readyEdit: false,
      loadingPropertyType: false,
      loadingPropertySubType: false,
      loadingCity: false,
      loadingLocation: false,
      updatingLandlord: false,
      showTenantData: '',
      showTenantInfo: false,
      propertyCategory: [],
      propertyTypes: [],
      propertyTitle: "",
      propertySubTypes: [],
      propertySubType: "",
      squareFootage: "",
      description: "",
      propertyValue: "",
      propertyType: "",
      noOfBedroom: "",
      noOfBathroom: "",
      noOfToilets: "",
      states: [],
      city: "",
      cities: [],
      businesstypenumber: '',
      propertyStatuses: [],
      userID: '',
      tab: 1,
      landlordDetails: 'view',
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      typeofbusiness: "",
      location: "",
      propertyAddress: "",
      errorMessage: {
        title: "",
        propertyTitle: "",
        noOfBedroom: "",
        noOfBathroom: "",
        noOfToilets: "",
        propertyType: "",
        propertySubType: "",
        squareFootage: "",
        description: "",
        // leaseOrRent: "",
        typeofbusiness: "",
        status: "",
        propertyValue: "",
        form: "",
        firstName: "",
        propertyAddress: "",
        lastName: "",
        email: "",
        phone: "",
        amount: "",
        amount2: "",
        address: "",
        age: "",
        gender: "",
        location: "",
        city: "",
        // evidenceServerFileName: "",
        propertyImageFile: "",
        evidenceFile: "",
      },
      
      duplicateFormNumber: '',
      forms: [
        {
          unit_name: '',
          price: '',
          bedrooms: '',
          bathrooms: '',
          toilets: '',
          status: '',
        },
      ],
      tenantsOboarding: [
        {
          name: 'Olivia Jackson Micheal',
          suit: 'Suit A',
          percentage: '70',
          id: 1
        },
        {
          name: 'Martins Salvador Okon',
          suit: 'Suit B',
          percentage: '40',
          id: 2
        }
      ]
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;

  },
  mounted() {
    this.getPropertyStatuses();
    this.getPropertyTypes();
    this.getStates();
    this.getBusinessTypes();
    this.getPropCategory();
  },
  beforeDestroy() {
  },
  methods: {
    backToLandlordList() {
      this.$router.push(`/user/landlords`);
    }, 
    addForm() {   
      const lastForm = this.forms[this.forms.length - 1]; // Get the last form
      // Validate if all fields of the last form are filled
      if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms|| !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        return;
      }
      // Add a new form if validation passes
      this.forms.push({ unit_name: '', price: '', bedrooms: '',  bathrooms: '', toilets: '', status: ''});
      console.log("Formssss", this.forms);  // Check if a new form is added
    },
    removeForm(index) {
      this.forms.splice(index, 1); // Remove 1 element at the given index
    },
    duplicateFormData() {
      const lastForm = this.forms[this.forms.length - 1]; 
      if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms|| !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        return;
      }
      const duplicatedArray = this.forms.flatMap(item => Array(Number(`${this.duplicateFormNumber}`)).fill({ ...item }));
      this.forms = duplicatedArray;
      console.log("Duplicated form", duplicatedArray, this.forms);
    }, 
    getPropCategory() {
      properties
        .fetchPropCategory()
        .then((data) => {
          // console.log("Category", data);
          if (data.success) {
            this.propertyCategory = data.categories;
          }
          // this.loadingLocation = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },
    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },
    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "") {
        this.errorMessage.propertySubType = "Please select value";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    validatePropertyAddress() {
      if (this.propertyAddress.trim() === "") {
        this.errorMessage.propertyAddress = " Please input property address";
        return false;
      }
      this.errorMessage.propertyAddress = "";
      return true;
    },
    validateDescription() {
      if (this.description.trim() === "") {
        this.errorMessage.description = " Property description required!";
        return false;
      }
      this.errorMessage.description = "";
      return true;
    },
    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
          //fetch subproperty on first load

          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateCity() {
      if (this.city === "") {
        this.errorMessage.city = "Please select city";
        return false;
      }

      this.errorMessage.city = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;
          // this.getCities();
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    

    getCities(id) {
      this.loadingCity = true;
      properties
        .fetchCities(id)
        .then((data) => {
          this.loadingCity = false;

          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    isForm2Valid() {
      return (
        this.validateLocation() && 
        this.validateCity() && 
        this.validateDescription() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() && 
        this.validateTitleInput() && 
        this.validatePropertyAddress() &&
        this.validateBusinessType()
        // this.validateBedrooms() &&
        // this.validateBathrooms() && 
        // this.validateToilets() && 
        // this.validateSquareFootage() &&
      );
    },

    validateForm2() {
      console.log("form validity", this.isForm2Valid())
      const lastForm = this.forms[this.forms.length - 1];
      if (!this.isForm2Valid() || !lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        return alert("form not valid1")
      }
      else {

        this.submittingProp = true;
        const body = {
          userId: this.userID,
          units_objects: this.forms,
          landlord_id: this.landLordId,
          // facilities: this.facilities,
          subtype: this.propertySubTypeId,
          type: this.propertyTypeId,
          cityid: this.cityId,
          stateid: this.stateId,
          address: this.propertyAddress,
          businesstype: Number(this.typeofbusiness),
          description: this.description,
          title: this.propertyTitle,
          images: this.uploadedPhotos,
          evidenceofownership: this.evidenceServerFileName,
          
        };
        properties.registerPropertyToLandlordStandAlone(JSON.stringify(body))
          .then((res) => {
            this.submittingProp = false;
            if (res.success) {
              (this.errorMessage.form = ""), (this.formSubmitted = true);
              // this.clearForm();
              this.$toast.success("SUCCESS, PROPERTY ADDED TO LANDLORD");
              this.$router.push(`/user/landlords`);
              // reset form step
            } else {
              this.$toast.error("ERROR");
            }
          })
          .catch((err) => {
            console.log(err);
            this.submittingProp = false;
          });
      }
    },
   
  },
  computed: {
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    sortedCity() {
      return [...this.cities].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },
    cityId() {
      return this.cities.find((el) => el.name === this.city).id || "";
    },
    landLordId() {
      return this.$route.params.id;
    } 
    // sForm1Valid() {
    //   return (
    //     this.validateFirstName() &&
    //     this.validateLastName() &&
    //     this.validatePhone() &&
    //     this.validateEmail() &&
    //     this.validateLandlordAddress() 
    //   );
    // },
  },
  watch: {
    duplicateFormNumber(newData) {
      if (newData) {
        this.duplicateFormData();
      }
    },
    propertyType(newType) {
       if (newType) {
        this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
          "id"
        ];
       }

        if (this.subtypeId) {
          this.getPropertySubTypes(this.subtypeId);
        } else {
          this.propertySubTypes = [];
        }
    },
    location(newState) {
       if (newState) {
        this.stateIdd = this.states.find((el) => el.name === newState)["id"];
       }

        if (this.stateIdd) {
          this.getCities(this.stateIdd);
        } else {
          this.cities = [];
        }
    },
    

  },
};
</script>

<style lang="scss" scoped>
.holder {
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
}
.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container2 {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}
.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
  padding: 5px 0;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

// Dynamic input starts here
.unit-count-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2B3352;
  }
}
.unit-text-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h6 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  button {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #0033ea;
    border-bottom: 1px solid #0033ea;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.addNewUnit {
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    margin-top: 10px;
  }
}

.dynamic-input {
  padding: 1rem 10px !important;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #57595a;
  font-size: 1rem;
  font-family: Lato;
  height: inherit;
  outline: 0;
  box-shadow: none;
  // height: 48px;
}
.prop-reviw-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
  }
}
.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

// Dynamic input ends here

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

.green-yellow-btn {
  margin-top: 40px;
  button {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    min-height: 48px;
    min-width: 106px;
    outline: 0;
    box-shadow: none;
  }
  .save {
    background: #2BC300;
  }
  .cancel {
    background: #EB0000;
    margin-right: 20px;
  }
}
</style>